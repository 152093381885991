.c-form {
  &--loading, &--saved {
    position: relative;

    &:before {
      @extend .c-overlay;
      @extend .c-overlay--visible;
      position: absolute;
      z-index: $z-over-content;
      content: '';
      background: rgba(white, .8);
    }
  }

  &--loading {
    &:after {
      content: '';
      position: absolute;
      top: 25%;
      left: 50%;
      margin: -1.5rem 0 0 -1.5rem;
      @include loadingSpinner();
    }
  }

  &--saved {
    &:before {
      opacity: 0;
      transition: opacity .5s;
    }

    &-visible {
      &:before {
        opacity: 1;
        transition: opacity 0;
      }
    }
  }

  &__confirm {
    margin-bottom: $spacing-small;
  }

  &__actions {
    position: relative;
    @include clearfix;

    &--next {
      float: right;
    }

    .c-form__confirm {
      margin-bottom: $spacing-medium;
    }
  }

  // required to be inside the __actions, so needs better naming?
  &__sub-actions {
    position: absolute;
    top: 0;
    right: 0;
    padding: inherit;
    // top: nth($form-element-padding, 1);
    // @if length($form-element-padding) >= 4 {
    //   right: nth($form-element-padding, 4);
    // } @else if length($form-element-padding) >= 2 {
    //   right: nth($form-element-padding, 2);
    // } @else {
    //   right: 0;
    // }
  }

  &__element {
    position: relative;

    .c-label {
      line-height: 1;
    }

    .c-label:first-child {
      padding: $form-element-label-padding;
    }
  }

  &__saved {
    position: absolute;
    top: 25%;
    left: 50%;
    width: 30em;
    margin: -2.5rem 0 0 -15em;
    z-index: $z-over-content + 1;
    text-align: center;
    opacity: 0;
    transition: opacity .5s;
    background-color: rgba(253, 253, 253, 0.7);

    &--active {
      opacity: 1;
      transition: opacity .3s;
    }
  }

  &__unexpected, &__save-error {
    position: absolute;
    top: 25%;
    left: 50%;
    width: 40em;
    margin: 2em 0 0 -20em;
    z-index: $z-over-content + 2;
    background-color: white;
    padding: 1px 3px;
    min-height: 2em;
    text-align: center;

    &--error {
      margin-top: -2em;
    }
  }

  &__save-error {
    margin-top: -2em;
  }

  &--compact {
    &.c-form--loading:after, &.c-form--saved:after {
      top: 30%;
      @include loadingSpinner(hsl(0, 0%, 46%), 2rem);
      margin: -1rem 0 0 -1rem;
    }

    .c-form__saved {
      top: 30%;
      margin-top: -1rem;
    }
  }
}

.o-fieldset + .c-form__element,
.o-fieldset + .c-form__confirm,
.o-fieldset + .c-form__actions,
.c-form__element + .c-form__element:not(.c-form__element--flush),
.c-form__element + .c-form__confirm,
.c-form__element + .c-form__actions {
  padding-top: $spacing-large;
}

.o-fieldset + .o-fieldset,
.c-form__element + .o-fieldset {
  margin-top: $spacing-large;
}

.c-form--compact {
  .o-fieldset + .c-form__element,
  .o-fieldset + .c-form__confirm,
  .o-fieldset + .c-form__actions,
  .c-form__element + .c-form__element:not(.c-form__element--flush),
  .c-form__element + .c-form__confirm,
  .c-form__element + .c-form__actions {
    padding-top: $spacing-xsmall;
  }
}

.c-form__element + .c-form__element--flush {
  padding-top: .5em;
}

.c-card .c-form__actions {
  margin-top: 1em;
  padding-top: 1em;
  border-top: 1px solid theme-color(gray1);
}

@charset "UTF-8";

/// Checks if a list contains a value(s).
///
/// @access private
///
/// @param {List} $list
///   The list to check against.
///
/// @param {List} $values
///   A single value or list of values to check for.
///
/// @example scss - Usage
///   contains($list, $value)
///
/// @return {Bool}

@function contains($list, $values...) {
  @each $value in $values {
    @if type-of(index($list, $value)) != "number" {
      @return false;
    }
  }

  @return true;
}

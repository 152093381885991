@import '../../../bower_components/bourbon/app/assets/stylesheets/bourbon';

@import 'node_modules/blaze/scss/blaze';
@import 'node_modules/blaze/scss/blaze.colors';

@import 'vendor/icon';

@import 'mixins/loading-spinner';
@import 'mixins/palx-colors';
@import 'mixins/status-colors';
@import 'mixins/theme-button';

@import 'components.icon';

@import 'utilities.alignment';
@import 'components.badges';
@import 'components.link';
@import 'components.buttons';
@import 'components.form';
@import 'components.inputs';
@import 'components.item';
@import 'components.tables';
@import 'components.navs';
@import 'components.headings';
@import 'components.tabs';
@import 'components.dropdown';
@import 'components.breadcrumbs';
@import 'components.blockquote';
@import 'components.cards';
@import 'components.tweet';
@import 'objects.containers';
@import 'objects.media';
@import 'objects.box';
@import 'objects.width';
@import 'objects.modal';
@import 'objects.postedin-loading';
@import 'objects.loading-spinner';
@import 'objects.fieldset';
@import 'objects.grid';
@import 'objects.profile-picture';
@import 'objects.dropzone';
@import 'objects.masker';
@import 'objects.upload-list';
@import 'utilities.spacing';
@import 'utilities.sticky';
@import 'utilities.misc';
@import 'misc.pace';
@import 'hacks.froala-comment';

@include palxColors();

// TODO: remove in develop
svg.icon {
  display: none;
}

// hacks are here that should be solved properly later
.u-block { display: block; }

.u-inline-block { display: inline-block; }

.u-normal {
  font-size: 1em;
}

.u-tiny {
  font-size: $text-font-size-tiny;
}

.u-froala-error {
  .fr-box.fr-basic.fr-top .fr-wrapper,
  .fr-toolbar.fr-top {
    border-color: $color-red;
  }
}

.u-pillar-box, .u-window-box {
  > .c-heading:first-child {
    padding-top: 0;
  }
}

.u-window-box + .u-window-box {
  margin-top: 2em;
}

a.u-window-box {
  display: block;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

.u-pointer {
  cursor: pointer;
}

@keyframes ellipsis {
  to {
    width: 1em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1em;
  }
}

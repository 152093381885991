.c-heading {
  line-height: 1.2;

  &--compact {
    padding: 0;
    line-height: 1em;
  }

  &__details {
    margin: .5em 0;
    font-size: .9rem;
    font-weight: normal;
    color: map-get($palxColors, 'gray7');
  }
}

h1.c-heading {
  font-size: $text-font-size-super;
}

h2.c-heading {
  font-size: $text-font-size-xlarge;
}

h3.c-heading {
  font-size: $text-font-size-medium;
}

h4.c-heading {
  font-size: 1em;
}

h5.c-heading {
  font-size: $text-font-size-small;
}

h5.c-heading {
  font-size: $text-font-size-small;
}

.c-dropdown-trigger {
  cursor: pointer;
  position: relative;

  &__caret {
    position: absolute;
    top: 7px;
    right: 7px;
    font-size: .9em;
  }

  &--open {
    cursor: default;
  }

  &:hover:not(.c-dropdown-trigger--open) {
    border-color: map-get($palxColors, blue2);
  }
}

.c-dropdown-menu {
  border: 1px solid map-get($palxColors, blue5);

  &__item {
    cursor: default;
    padding: .5em;
    background-color: map-get($palxColors, white);
    border-bottom: 1px solid map-get($palxColors, gray1);

    &:last-child {
      border-bottom: 0 none;
    }

    &:hover {
      color: map-get($palxColors, white);
      background-color: map-get($palxColors, blue4);
    }
  }
}

.o-loading-spinner {
  margin: auto;
  @include loadingSpinner(map-get($palxColors, 'base'));

  &--left-aligned {
    margin: 0;
  }

  &--inline {
    margin: 0 .5em;
    display: inline-block;
    vertical-align: middle;
    @include loadingSpinner(map-get($palxColors, 'base'), 1.75em);
  }

  &--navbar {
    @include loadingSpinner(map-get($palxColors, 'base'), 2em);
  }
}

$palxColors: (
  brand: darken(#00CDA9, 3),
  quiet: #96A8B2,
  black: #33423f,
  white: #fff,

  base: darken(#00CDA9, 3),
  gray0: #f8f9f9,
  gray1: #ebeeed,
  gray2: #dce1e0,
  gray3: #ccd4d3,
  gray4: #bbc5c4,
  gray5: #a8b5b3,
  gray6: #92a3a0,
  gray7: #788d89,
  gray8: #58706d,
  gray9: #33423f,
  cyan0: #e3f9f5,
  cyan1: #c4f3ea,
  cyan2: #a1ecde,
  cyan3: #77e4d0,
  cyan4: #44dabe,
  cyan5: #00cda7,
  cyan6: #00b997,
  cyan7: #00a284,
  cyan8: #00876e,
  cyan9: #00614f,
  blue0: #e3f2f9,
  blue1: #c5e4f3,
  blue2: #a2d5ec,
  blue3: #79c2e4,
  blue4: #47acda,
  blue5: #008ccd,
  blue6: #007eb9,
  blue7: #006ea2,
  blue8: #005b85,
  blue9: #00415f,
  indigo0: #e8ecfa,
  indigo1: #cfd6f5,
  indigo2: #b3bef0,
  indigo3: #90a0e9,
  indigo4: #6178e0,
  indigo5: #0026cd,
  indigo6: #0022b8,
  indigo7: #001da0,
  indigo8: #001883,
  indigo9: #00105b,
  violet0: #efeafa,
  violet1: #ddd2f6,
  violet2: #c9b7f0,
  violet3: #b095ea,
  violet4: #8e68e1,
  violet5: #4100cd,
  violet6: #3a00b9,
  violet7: #3300a1,
  violet8: #2a0085,
  violet9: #1e005f,
  fuschia0: #f7e9fa,
  fuschia1: #efd1f6,
  fuschia2: #e5b5f0,
  fuschia3: #d993e9,
  fuschia4: #c964e0,
  fuschia5: #a700cd,
  fuschia6: #9700b9,
  fuschia7: #8400a2,
  fuschia8: #6d0086,
  fuschia9: #4e0060,
  pink0: #fae9f5,
  pink1: #f6d1ea,
  pink2: #f0b6de,
  pink3: #ea94ce,
  pink4: #e166ba,
  pink5: #cd008c,
  pink6: #b9007e,
  pink7: #a2006f,
  pink8: #87005c,
  pink9: #610042,
  red0: #faeaed,
  red1: #f6d2d9,
  red2: #f0b7c1,
  red3: #ea95a5,
  red4: #e1687e,
  red5: #cd0026,
  red6: #b90022,
  red7: #a2001e,
  red8: #870019,
  red9: #610012,
  orange0: #faece5,
  orange1: #f4d7c9,
  orange2: #eebfa9,
  orange3: #e6a282,
  orange4: #dc7d50,
  orange5: #cd4100,
  orange6: #b93a00,
  orange7: #a13300,
  orange8: #852a00,
  orange9: #5e1e00,
  yellow0: #f8f3df,
  yellow1: #f1e7bc,
  yellow2: #eada96,
  yellow3: #e2cb6b,
  yellow4: #d8bb3a,
  yellow5: #cda700,
  yellow6: #b99700,
  yellow7: #a28400,
  yellow8: #866d00,
  yellow9: #604e00,
  lime0: #f0f8df,
  lime1: #e0f1bc,
  lime2: #cfea95,
  lime3: #bce16a,
  lime4: #a6d839,
  lime5: #8ccd00,
  lime6: #7eb900,
  lime7: #6fa200,
  lime8: #5c8700,
  lime9: #426100,
  green0: #e7f9e3,
  green1: #ccf3c4,
  green2: #afeca1,
  green3: #8ce477,
  green4: #60da45,
  green5: #26cd00,
  green6: #22b900,
  green7: #1ea200,
  green8: #198700,
  green9: #126100,
  teal0: #e4f9ea,
  teal1: #c5f3d4,
  teal2: #a3edba,
  teal3: #7ae59c,
  teal4: #47db76,
  teal5: #00cd41,
  teal6: #00b93a,
  teal7: #00a233,
  teal8: #00872a,
  teal9: #00611f,
);

@function theme-color($color, $number: '') {
  @return map-get($palxColors, #{$color}#{$number});
}

$statusColors: (
  pending: theme-color(gray5),
  requested: theme-color(fuschia4),
  researching: theme-color(fuschia5),
  searching: theme-color(fuschia6),
  producing: theme-color(blue5),
  quality_assurance: theme-color(blue7),
  submitted: theme-color(cyan5),
  revising: theme-color(indigo5),
  reviewing: theme-color(indigo7),
  approved: theme-color(green6),
  rejected: theme-color(red5),
  closed: theme-color(gray7),
  canceled: theme-color(red5),
);

// same meaning in different content types we can reuse
$statusColors: map-merge($statusColors, (
  writing: map-get($statusColors, producing),
  designing: map-get($statusColors, producing),
  done: map-get($statusColors, approved),
));

$background-color: #f8f9fa;

$color-quiet: map-get($palxColors, 'quiet');
$color-grey: map-get($palxColors, 'gray4');
$color-white: #FFF;
$color-red: map-get($palxColors, 'red5');
$color-pink: map-get($palxColors, 'pink5');
$color-purple: map-get($palxColors, 'fuschia5');
$color-blue: map-get($palxColors, 'blue5');
$color-green: map-get($palxColors, 'green6');
$color-cyan: map-get($palxColors, 'cyan5');
$color-yellow: map-get($palxColors, 'yellow5');
$color-orange: map-get($palxColors, 'orange4');
$color-brown: map-get($palxColors, 'red9');
$color-beige: lighten(map-get($palxColors, 'base'), 40); // this colour is so bad, use light version of brand

$color-brand: map-get($palxColors, 'base');

$code-background-color: map-get($palxColors, 'gray1');

$quotation-padding: .5em;

$nav-light-background-color: map-get($palxColors, 'gray1');

$screen-limit-xsmall: 20em;
$screen-limit-small: 34em;
$screen-limit-medium: 54em;
$screen-limit-large: 74em;
$screen-limit-xlarge: 96.2em;
$screen-limit-super: 112em;

$grid-gutter: .75em !default;

$modal-width: 65em;
$modal-border-radius: 0;
$modal-border-width: 1px;
$modal-border-style: solid;
$modal-border-color: theme-color(gray7);

$overlay-background-color: theme-color(black);

$text-font-size-super: 2em;
$text-font-size-xlarge: 1.5em;
$text-font-size-large: 1.25em;
$text-font-size-medium: 1.1em;
$text-font-size-small: .925em;
$text-font-size-xsmall: .8em;
$text-font-size-tiny: .7em;

$heading-padding: .5em 0;

$color-tint: 2;

$table-margin: 0 0 2em;
$table-heading-background-color: map-get($palxColors, gray1);
$table-heading-font-weight: normal;
$table-heading-font-size: .98em;
$table-heading-border-color: map-get($palxColors, gray2);

$field-disabled-color: map-get($palxColors, gray7);
$field-disabled-background-color: map-get($palxColors, gray2);
$field-readonly-color: map-get($palxColors, gray9);
$field-readonly-background-color: map-get($palxColors, gray0);

$badge-font-size: 1em;

@import 'node_modules/blaze/scss/mixins/settings.global';

$button-padding: $spacing-small $spacing-medium;
$button-border-radius: 2px;

$sizes: none, tiny, xsmall, small, medium, large, xlarge, super;
$directions: top, right, bottom, left;
$spacings: (
  none: 0,
  tiny: $spacing-tiny,
  xsmall: $spacing-xsmall,
  small: $spacing-small,
  medium: $spacing-medium,
  large: $spacing-large,
  xlarge: $spacing-xlarge,
  super: $spacing-super,
);

.u-right {
  float: right;
  text-align: inherit;
}

.u-left {
  float: left;
  text-align: inherit;
}

.u-left-aligned {
  text-align: left;
}

.u-center-aligned {
  text-align: center;
}

.u-right-aligned {
  text-align: right;
}

.u-top-aligned {
  vertical-align: top;
}

.u-bottom-aligned {
  vertical-align: bottom;
}

.u-baseline-aligned {
  vertical-align: baseline;
}

.u-middle-aligned {
  vertical-align: middle;
}

.o-fieldset {
  margin-top: 0;
}

.o-fieldset--bordered, .o-fieldset + .o-fieldset {
  margin-top: $spacing-xlarge;
  padding-top: $spacing-xlarge;
  border-top: 1px solid theme-color(gray, 2);
}

.o-fieldset__legend {
  font-size: $text-font-size-medium;
  font-weight: bold;

  &--minor {
    font-size: 1em;
  }
}

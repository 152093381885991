.c-badge {
  border-radius: 0;
  font-weight: normal;
  padding: 0.2em 0.45em 0.22em;

  &--rounded {
    border-radius: $badge-border-radius-rounded;
  }
}

.c-badge--text {
  border: 0 none;
  background-color: transparent;
  color: theme-color(gray7);
  padding: 0;
  font-size: $text-font-size-small;
  font-weight: bold;
}

@each $name, $color in $statusColors {
  @include badgeStatusModifiers($name, $color);
}

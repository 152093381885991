@function status-color($color) {
  @return map-get($palxColors, $color);
}

@mixin badgeStatusModifiers($name, $color) {
  .c-badge--#{$name} {
    background-color: $color;
    border-color: darken($color, 5);

    &.c-badge--ghost {
      color: $color;
      border-color: $color;
      background-color: transparent;
    }

    &.c-badge--text {
      color: $color;
      background-color: transparent;
    }
  }
}

.o-masker {
  &__source {
    opacity: 1;
  }

  &__content {
    display: none;
  }

  &--active {
    position: relative;

    .o-masker__source {
      transition: opacity .2s;
      opacity: .4;
    }

    .o-masker__content {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .o-masker__body {
      position: absolute;
      top: 40%;
      width: 100%;
      text-align: center;

      .c-card {
        width: 40%;
        margin: auto;
      }
    }
  }
}

.o-modal--small {
  .o-masker__body {
    font-size: $text-font-size-small;
    top: 0;

    .c-card {
      width: 70%;
    }
  }
}

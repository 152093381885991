@mixin theme-button($color, $light: false) {
  font-weight: bold;
  padding: 0.4em 0.6em;
  text-align: center;

  &, &:disabled, &.c-button--disabled, &.c-button--disabled:hover {
    @if ($light) {
      color: theme-color($color, 9);
      background-color: theme-color($color, 1);
      border: 1px solid theme-color($color, 4);
    } @else {
      color: darken(white, 3);
      background-color: theme-color($color, 6);
      background-image: linear-gradient(to bottom, theme-color($color, 5), darken(theme-color($color, 5), 2));
      border: 1px solid theme-color($color, 7);
    }
  }

  &:not(:disabled):not(.c-button--disabled):hover {
    @if ($light) {
      background-color: lighten(theme-color($color, 1), 1.7);
      background-image: linear-gradient(top, theme-color($color, 1), lighten(theme-color($color, 1), 1.7));
      color: darken(theme-color($color, 9), 1.5);
    } @else {
      color: white;
      background-color: darken(theme-color($color, 5), 1.5);
      background-image: linear-gradient(to bottom, theme-color($color, 5), theme-color($color, 6));
    }
  }
}

.c-blockquote {
  font-size: 1em;

  &__body {
    padding: 0;
  }

  &__footer {
    color: theme-color(gray6);
  }
}

.c-blockquote--compact {
  padding: .3em .5em .2em;
  border-left-width: 3px;

  .c-blockquote__body {
    font-size: $text-font-size-medium;
    line-height: 1.3;
    padding: 0;
  }

  .c-blockquote__footer {
    padding-bottom: 0;
  }
}

@charset "UTF-8";

/// Checks for a valid CSS length.
///
/// @param {String} $value

@function is-length($value) {
  @return type-of($value) != "null" and (str-slice($value + "", 1, 4) == "calc"
       or index(auto inherit initial 0, $value)
       or (type-of($value) == "number" and not(unitless($value))));
}

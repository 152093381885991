// Private function for linear-gradient-parser
@function _linear-angle-parser($image, $first-val, $prefix, $suffix) {
  $offset: null;
  $unit-short:  str-slice($first-val, str-length($first-val) - 2, str-length($first-val));
  $unit-long:   str-slice($first-val, str-length($first-val) - 3, str-length($first-val));

  @if ($unit-long == "grad") or
      ($unit-long == "turn") {
    $offset: if($unit-long == "grad", -100grad * 3, -0.75turn);
  }

  @else if ($unit-short == "deg") or
           ($unit-short == "rad") {
    $offset: if($unit-short == "deg", -90 * 3, 1.6rad);
  }

  @if $offset {
    $num: _str-to-num($first-val);

    @return (
      webkit-image: -webkit- + $prefix + ($offset - $num) + $suffix,
      spec-image: $image
    );
  }
}

.c-tabs {
  &--cover {
    margin-bottom: -1px;
  }

  // because blaze override
  .c-tabs__nav .c-tabs__headings {
    display: block;
    text-align: left;
    border-bottom: .2em solid theme-color(gray, 1);
    padding-bottom: 0;
    margin-bottom: 0;
    overflow: visible;
    cursor: default;
  }

  &--wizard {
    .c-tabs__headings {
      border-bottom: 0 none;
    }
  }
}

.c-tab-heading {
  flex: 0;
  display: inline-block;
  box-shadow: none;
  border-bottom: .2em solid transparent;
  margin-bottom: -.2em;
  z-index: 2;
  color: theme-color(gray, 7);
  font-size: $text-font-size-small;

  &:hover {
    text-decoration: none;
  }

  &:hover {
    color: theme-color(gray9);
  }

  &--active {
    color: theme-color(gray9);
    border-bottom-color: theme-color(gray6);
  }

  &--wizard {
    padding: $spacing-small $spacing-medium $spacing-small $spacing-large;
    border: 0 none;
    color: theme-color(gray, 8);
    font-size: 1em;
    font-weight: normal;
    border-right: 1px solid theme-color(gray, 2);
    position: relative;
    margin: 0;

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      right: -1px;
      width: $text-font-size-small;
      height: $text-font-size-small;
      background-color: white;
      border-right: 1px solid theme-color(gray, 2);
      border-bottom: 1px solid theme-color(gray, 2);
      transform: translateY(-50%) translateX(50%) rotate(-45deg);
    }

    &:first-child {
      padding-left: $spacing-medium;
      border-left: 0 none;
      border-top-left-radius: 4px;
    }

    &:last-child {
      border-right: 0 none;

      &:after {
        display: none;
      }
    }

    &.c-tab-heading--active {
      font-weight: bold;
    }
  }
}

.c-tabs--box {
  .c-tabs__headings {
    border-top: 0 none;
    border-right: 0 none;
  }

  .c-tab-heading:first-child {
    border-left: 0 none;
  }

  .c-tab-heading--active {
    background-color: theme-color(white);
    border-bottom-color: theme-color(white);
    border-top-color: theme-color(gray8);
  }
}

.c-tab-heading--brand {
  color: $color-brand;

  &:hover {
    color: darken($color-brand, 5);
  }

  &.c-tab-heading--active {
    border-top-color: $color-brand;
  }
}

.c-tab-heading--info {
  color: $color-info;

  &:hover {
    color: darken($color-info, 5);
  }

  &.c-tab-heading--active {
    border-top-color: $color-info;
  }
}

.c-tab-heading--warning {
  color: $color-warning;

  &:hover {
    color: darken($color-warning, 5);
  }

  &.c-tab-heading--active {
    border-top-color: $color-warning;
  }
}

.c-tab-heading--success {
  color: $color-success;

  &:hover {
    color: darken($color-success, 5);
  }

  &.c-tab-heading--active {
    border-top-color: $color-success;
  }
}

.c-tab-heading--error {
  color: $color-error;

  &:hover {
    color: darken($color-error, 5);
  }

  &.c-tab-heading--active {
    border-top-color: $color-error;
  }
}

.c-tab-heading--disabled {
  &, &:hover {
    color: theme-color(gray5);
  }
}

.o-box {
  background-color: white;
  border: 1px solid theme-color(gray2);
  position: relative;

  &--cover {
    margin-bottom: -1px;
  }

  &--rounded {
    border-radius: 4px;
  }

  &--top {
    border-right: 0 none;
    border-top: 0 none;
    border-left: 0 none;
  }

  &--image {
    line-height: 0;
    padding: $spacing-tiny;
  }

  &--selectable {
    cursor: pointer;

    &:hover {
      border-color: theme-color(gray6);
    }
  }

  &--selected {
    &, &:hover {
      border-color: theme-color(blue5);
    }
  }

  &__selected-indicator {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: $spacing-tiny;
    height: $text-font-size-xsmall * 2;
    line-height: $text-font-size-xsmall * 2;
    font-size: $text-font-size-xsmall;
    font-weight: bold;
    text-align: center;
    color: white;
    background-color: rgba(theme-color(blue6), .7);
  }

  &--selected .o-box__selected-indicator {
    display: block;
  }

  @each $size in $sizes {
    &--#{$size} {
      padding: map-get($spacings, $size);
    }
  }

  &--emphasize {
    background-color: theme-color(blue0);
  }

  > .c-heading:first-child,
  > .o-grid:first-child > .o-grid__cell > .c-heading:first-child {
    padding-top: 0;
  }

  > .c-heading:only-child {
    padding-bottom: 0;
  }
}

.o-box-wrapper {
  position: relative;
}

.o-box-action {
  display: none;
  position: absolute;
  cursor: pointer;
  top: 2px;
  right: 2px;
  height: $text-font-size-small * 2;
  min-width: 2.5em;
  line-height: $text-font-size-small * 2;
  font-size: $text-font-size-small;
  text-align: center;
  color: white;
  border: 1px solid theme-color(gray6);
  background-color: rgba(black, .6);

  &:hover {
    background-color: black;
  }
}

.o-box-action-confirm {
  position: absolute;
  cursor: pointer;
  top: 2px;
  right: 2px;
  left: 2px;
  padding: $spacing-xsmall;
  font-size: $text-font-size-small;
  text-align: center;
  color: white;
  border: 1px solid theme-color(gray6);
  background-color: theme-color(gray9);

  // started hacking...

  p {
    margin: 0 0 $spacing-xsmall;
    font-weight: bold;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.o-box:hover + div > .o-box-action,
.o-box:hover + .o-box-action,
.o-box-action:hover {
  display: block;
}

.c-button--close:not(:disabled):hover {
  @include button-color(transparent, inherit);
}

a.c-button, a.c-button:hover {
  text-decoration: none;
}

.c-button {
  font-weight: bold;
  min-width: 6em;

  &--plain { @include theme-button(gray, true); }
  &--gray { @include theme-button(gray); }
  &--cyan { @include theme-button(cyan); }
  &--blue { @include theme-button(blue); }
  &--indigo { @include theme-button(indigo); }
  &--violet { @include theme-button(violet); }
  &--fuschia { @include theme-button(fuschia); }
  &--pink { @include theme-button(pink); }
  &--red { @include theme-button(red); }
  &--orange { @include theme-button(orange); }
  &--yellow { @include theme-button(yellow); }
  &--lime { @include theme-button(lime); }
  &--green { @include theme-button(green); }
  &--teal { @include theme-button(teal); }

  &--icon {
    background-color: transparent;
    border: 1px solid transparent;
    padding: 4px 10px;
    min-width: 0;
    color: theme-color(gray, 8);

    &:not(:disabled):not(.c-button--disabled):hover {
      color: theme-color(blue, 8);
      background-color: transparent;
      background-image: linear-gradient(to bottom, theme-color(blue, 0), darken(theme-color(blue, 0), 2));
      border-color: theme-color(blue, 3);
    }

    svg {
      font-size: $text-font-size-xlarge;
    }
  }

  &:disabled, &--disabled {
    opacity: .5;
    cursor: default;
  }

  &--leading-icon {
    svg, i {
      margin-right: $spacing-xsmall;
    }
  }

  &--trailing-icon {
    svg, i {
      margin-left: $spacing-xsmall;
    }
  }

  &--close {
    min-width: 0;
    &, &:not(:disabled):not(.c-button--disabled):hover {
      color: theme-color(gray, 9);
    }
  }

  &.u--small, &.u--xsmall {
    font-weight: bold;
  }
}

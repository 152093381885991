.o-postedin-loading {
  &__spinner {
    margin: 0 auto 20px;
    @include loadingSpinner(map-get($palxColors, 'base'));
  }

  &__inner {
    text-align: center;
    margin-top: 4%;
    margin-left: auto;
    margin-right: auto;
  }
}

.u-quiet {
  color: $color-quiet;
}

// hack
.ember-power-select-option[aria-current="true"] .u-quiet {
  color: #fff;
}

.u-credits {
  ins, del {
    text-decoration: none;
    font-weight: bold;
    font-size: $text-font-size-medium;
  }

  ins {
    color: theme-color(green, 6);
  }

  del {
    color: theme-color(red, 5);
  }

  span {
    font-family: 'Droid Serif', serif;
  }
}

.u-hidden {
  display: none !important;
}

.u-full-height {
  height: 100%;
}

.u-margin-auto {
  margin: auto;
}

.u-article {
  font-size: 16px;
  line-height: 23px;

  h1, h2, h3, h4, h5, h6 {
    &:first-child {
      margin-top: 0;
    }
  }

  h1:not(.c-header), h2:not(.c-header) {
    font-size: $text-font-size-super - .2em;
  }

  h3, h4 {
    font-size: $text-font-size-xlarge;
  }

  h5, h6 {
    font-size: $text-font-size-medium;
  }
}

.u-letter-box--flush-bottom {
  padding-bottom: 0;
}

.u-letter-box--flush-top {
  padding-top: 0;
}

.u-number {
  font-family: 'Droid Serif', serif;
}

.u-clearfix {
  @include clearfix;
}

.u-valign--top {
  vertical-align: top;
}

.u-valign--middle {
  vertical-align: middle;
}

.u-valign--bottom {
  vertical-align: bottom;
}

.u-ellipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.c-card {
  &--transparent {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  &--overlay {
    border-radius: 0;
  }

  &__tabs {
    padding: $card-header-padding;
    padding-top: $spacing-large;
    border-bottom: 1px solid theme-color(gray2);

    .c-tabs {
      width: 60%;
    }
  }

  &__body {
    &--tab-content {
      padding-bottom: $spacing-large;
      background-color: $background-color;
      border-bottom: 1px solid theme-color(gray2);
    }

    &--full {
      max-height: 400px;
      height: 80vh;
      overflow-y: auto;
    }

    &--dropzone {
      padding: 0;
      position: relative;
    }
  }
}

.c-breadcrumbs {
  @include clearfix;
  font-size: $text-font-size-small;
  color: theme-color(gray, 7);

  // TODO: remove in new design
  + .c-heading, + .o-grid > .o-grid__cell:first-child > .c-heading:first-child {
    padding-top: 0;
  }

  &--top {
    // TODO: uncomment this line on new design
    // border-bottom: 1px solid theme-color(gray, 2);
  }

  a, .c-link, .c-link:visited {
    color: theme-color(gray, 6);
  }

  &__crumb {
    float: left;
    padding: .7em 0 .5em;

    &:not(:last-child):after {
      padding: 0 .6em;
    }
  }
}

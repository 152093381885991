// Requires Sass 3.1+
@mixin radial-gradient($g1, $g2,
                       $g3: null, $g4: null,
                       $g5: null, $g6: null,
                       $g7: null, $g8: null,
                       $g9: null, $g10: null,
                       $pos: null,
                       $shape-size: null,
                       $fallback: null) {

  $data: _radial-arg-parser($g1, $g2, $pos, $shape-size);
  $g1:  nth($data, 1);
  $g2:  nth($data, 2);
  $pos: nth($data, 3);
  $shape-size: nth($data, 4);

  $full: $g1, $g2, $g3, $g4, $g5, $g6, $g7, $g8, $g9, $g10;

  // Strip deprecated cover/contain for spec
  $shape-size-spec: _shape-size-stripper($shape-size);

  // Set $g1 as the default fallback color
  $first-color: nth($full, 1);
  $fallback-color: nth($first-color, 1);

  @if (type-of($fallback) == color) or ($fallback == "transparent") {
    $fallback-color: $fallback;
  }

  // Add Commas and spaces
  $shape-size: if($shape-size, "#{$shape-size}, ", null);
  $pos:        if($pos, "#{$pos}, ", null);
  $pos-spec:   if($pos, "at #{$pos}", null);
  $shape-size-spec: if(($shape-size-spec != " ") and ($pos == null), "#{$shape-size-spec}, ", "#{$shape-size-spec} ");

  background-color:  $fallback-color;
  background-image: -webkit-radial-gradient(#{$pos}#{$shape-size}#{$full});
  background-image: radial-gradient(#{$shape-size-spec}#{$pos-spec}#{$full});
}

@function _shape-size-stripper($shape-size) {
  $shape-size-spec: null;
  @each $value in $shape-size {
    @if ($value == "cover") or ($value == "contain") {
      $value: null;
    }
    $shape-size-spec: "#{$shape-size-spec} #{$value}";
  }
  @return $shape-size-spec;
}

.c-tweet {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.1);
  background-clip: padding-box;
  padding: 30px 40px;

  &--compact {
    padding: 15px 20px;
  }

  &__body {
    font-size: 27px;
    line-height: 32px;
    letter-spacing: .01em;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  &--compact .c-tweet__body {
    font-size: 20px;
    line-height: 27px;
  }

  &__media {
    margin-top: 10px;
    max-height: 420px;
    max-width: 560px;
    border-radius: 5px;
    overflow: hidden;
    vertical-align: top;
    width: 100%;
  }

  &__meta {
    margin-top: 10px;
    font-size: 14px;
    line-height: 24px;
    color: #657786;
  }
}

.c-tweet-header {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  margin-left: 58px;
  @include clearfix;

  &__content {
    align-items: center;
    display: flex;
    height: 48px;
    margin-bottom: 15px;
  }

  &__profile {
    flex: 1;
  }

  &__avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    float: left;
    margin-left: -58px;
    position: absolute;
  }

  &__name {
    display: block;
    line-height: 24px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    word-break: break-all;
    color: #14171a;
    font-size: 18px;
    font-weight: bold;
  }

  &__username {
    font-size: 14px;
    color: #657786;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
  }

  &__follow {
    align-items: center;
    display: flex;
    position: relative;
  }

  &__follow-button {
    border-radius: 100px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    padding: 6px 16px;
    background-color: #fff;
    border: 1px solid #1B95E0;
    color: #1B95E0;
    text-align: center;
    min-width: 105px;
  }

  &__dropdown {
    display: inline-block;
    margin: -2px -9px 0 auto;
    padding: 2px 9px 2px 18px;
    color: #657786;
    font-size: 12px;
  }
}

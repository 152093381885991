@charset "UTF-8";

/// Provides a quick method for targeting `padding` on specific sides of a box. Use a `null` value to “skip” a side.
///
/// @param {Arglist} $vals
///   List of arguments
///
/// @example scss - Usage
///   .element {
///     @include padding(12vh null 10px 5%);
///   }
///
/// @example css - CSS Output
///   .element {
///     padding-bottom: 10px;
///     padding-left: 5%;
///     padding-top: 12vh;
///   }
///
/// @require {mixin} directional-property
///
/// @output `padding`

@mixin padding($vals...) {
  @include directional-property(padding, false, $vals...);
}

.c-link--inherit, .c-link--icon {
  &, &:hover, &:focus, &:active {
    color: inherit;
    text-decoration: inherit;
  }
}

.c-link--inherit-base {
  color: inherit;
  text-decoration: inherit;

  &:hover {
    text-decoration: inherit;
  }
}

.c-link--disabled, a.c-link--disabled {
  cursor: default;
  color: theme-color(gray5);
}

.c-link--alert {
  color: $alert-color;
  font-weight: bold;
  text-decoration: underline;

  &:hover, &:focus, &:active {
    color: darken($alert-color, $color-tint);
    text-decoration: underline;
  }
}

.u-sticky {
  position: sticky;
  top: 1em;
  max-height: calc(100vh - 2em);
  overflow: auto;
  z-index: $z-over-control;

  &--flush {
    top: 0;
    max-height: 100vh;
  }

  &--bottom {
    top: none;
    bottom: 1em;

    &.u-sticky--flush {
      bottom: 0;
    }
  }
}

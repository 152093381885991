// eg. .u-bottom-spacing--large
@each $direction in $directions {
  @each $size in $sizes {
    .u-#{$direction}-spacing--#{$size} {
      margin-#{$direction}: map-get($spacings, $size);
    }

    .u-#{$direction}-padding--#{$size} {
      padding-#{$direction}: map-get($spacings, $size);
    }
  }
}

.u-icon-spacing {
  min-width: $spacing-large;
}

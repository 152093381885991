.c-nav__item > a:only-child {
  display: block;
  color: inherit;
  margin-left: -$nav-item-padding;
  margin-right: -$nav-item-padding;
  padding-left: $nav-item-padding;
  padding-right: $nav-item-padding;

  &, &:hover {
    text-decoration: none;
  }
}

// reset shitty restrictive blaze tables
.c-table {
  display: table;
  flex: initial;
  flex-wrap: initial;

  &--no-margin {
    margin-bottom: 0;
  }

  &--hover .c-table__row:hover {
    background-color: theme-color(gray, 0);
  }

  &__head,
  &__body,
  &__row,
  &__cell,
  &__row--heading .c-table__cell {
    flex: initial;
    flex-wrap: initial;
    overflow: initial;
    border-top: 0 none;
  }

  &__head {
    display: table-header-group;
  }

  &__body {
    display: table-row-group
  }

  &__foot {
    display: table-footer-group;
  }

  &__row {
    display: table-row;
  }

  &__cell {
    border-top: 1px solid theme-color(gray, 2);
  }

  &__cell, &__row--heading .c-table__cell {
    display: table-cell;
    vertical-align: middle;

    &--top {
      vertical-align: top;
    }

    &--attach-below {
      padding-bottom: 0;
    }

    &--attach-above {
      border-top: 0 none;
      padding-top: 0;
    }
  }

  &__row--heading .c-table__cell {
    font-weight: bold;
    font-size: 1em;
  }
}

.c-table__cell--collapsing {
  width: 1px;
  white-space: nowrap;
}

select.c-field:not([multiple]) {
  background-image: none;
}

.c-label {
  font-weight: bold;
}

.c-field--choice {
  padding: 0;
  font-size: 1.05em;
  width: auto;
}

.c-field[type="search"] {
  box-sizing: border-box;
}

.c-field[type="search"]:read-only,
.c-field[type="text"]:read-only,
.c-field[type="email"]:read-only,
.c-field[type="password"]:read-only,
.c-field[type="number"]:read-only,
.c-field[type="tel"]:read-only,
.c-field[type="url"]:read-only,
textarea.c-field:read-only, {
  color: $field-readonly-color;
  background-color: $field-readonly-background-color;
}

.c-field--listed + .c-field--listed {
  margin-top: .5em;
}

.o-field-loading {
  position: relative;

  .c-field:disabled, .c-field--disabled {
    background-color: darken($field-background-color, 2);
  }

  &:after {
    content: '';
    position: absolute;
    top: .5em;
    left: .5em;
    @include loadingSpinner(hsl(0, 0%, 46%), 2em);
  }
}

.h-froala-comment {
  .fr-box.fr-basic.fr-top .fr-wrapper {
    box-shadow: none;
    @extend .c-field;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .fr-box.fr-basic .fr-element {
    padding: 0;
  }

  .fr-wrapper.show-placeholder .fr-placeholder {
    padding: $field-padding !important;
  }

  .fr-toolbar {
    display: none;
  }
}

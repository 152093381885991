.pace, .pace .pace-progress {
  position: fixed;
  top: 0;
  width: 100%;
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  left: 0;
  z-index: 9010;
  -webkit-transform: translate3d(0, -50px, 0);
  -ms-transform: translate3d(0, -50px, 0);
  transform: translate3d(0, -50px, 0);
  -webkit-transition: -webkit-transform .5s ease-out;
  -ms-transition: -webkit-transform .5s ease-out;
  transition: transform .5s ease-out;
}

.pace.pace-active {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.pace .pace-progress {
  display: block;
  z-index: 2000;
  right: 100%;
  height: 5px;
  background: map-get($palxColors, 'base');
  pointer-events: none;
}

.pace-disabled .pace {
  display: none;
}

.o-dropzone {
  height: 100%;
  position: relative;

  &--fill {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &--inline {
    display: inline-block;
    font-size: .75em;
  }

  &--active {
    background-color: theme-color(cyan0);
  }

  &__mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(white, .9);
  }

  &__single-uploading {
    position: absolute;
    top: 35%;
    right: 2em;
    left: 2em;

    &-text {
      font-size: $text-font-size-small;
      margin-bottom: $spacing-tiny;

      &--animated {
        &:after {
          overflow: hidden;
          display: inline-block;
          vertical-align: bottom;
          -webkit-animation: ellipsis steps(4, end) 2s infinite;
          animation: ellipsis steps(4, end) 2s infinite;
          content: "\2026";
          width: 0px;
        }
      }
    }
  }

  &__queue {
    padding: 1em 3em;
  }

  &__file {
    margin-bottom: .5em;
  }

  &__file-name {
    font-size: $text-font-size-small;
    margin-bottom: $spacing-tiny;
  }

  &__zone {
    padding: 4em 0 .8em;
    text-align: center;
    font-size: 1.7em;
    color: theme-color(gray6);
  }

  &--inline .o-dropzone__zone {
    padding-top: .2em;
  }

  &__zone-icon {
    font-size: $text-font-size-super;
    margin-bottom: $spacing-tiny;
  }

  &__zone-description {
    margin-bottom: $spacing-small;
  }

  &__zone-or {
    font-size: .6em;
    font-weight: bold;
  }

  &__input {
    text-align: center;
  }
}

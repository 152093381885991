@mixin palxUtilityColor($name, $color) {
  .u-c-#{$name} {
    color: $color;

    &.c-table__row .c-table__cell {
      color: $color;
    }
  }

  .u-bg-#{$name} {
    background-color: $color;

    &.c-table__row .c-table__cell {
      background-color: $color;
    }
  }

  .u-bgh-#{$name}:hover {
    background-color: $color;
  }

  .c-button.u-bg-#{$name}:hover {
    color: inherit;
    background-color: darken($color, 10%);
  }

  .u-b-#{$name} {
    border-color: $color;
  }

  .u-bh-#{$name}:hover {
    border-color: $color;
  }

  .u-bt-#{$name} {
    border-top-color: $color;
  }

  .u-br-#{$name} {
    border-right-color: $color;
  }

  .u-bb-#{$name} {
    border-bottom-color: $color;
  }

  .u-bl-#{$name} {
    border-left-color: $color;
  }
}

@mixin palxColors() {
  .u-b {
    border: 1px solid;
  }

  .u-bt {
    border-top: 1px solid;
  }

  .u-bt-thick {
    border-top-width: 3px;
  }

  .u-br {
    border-right: 1px solid;
  }

  .u-bb {
    border-bottom: 1px solid;
  }

  .u-bl {
    border-left: 1px solid;
  }

  .u-bra-0 {
    border-radius: 0px;
  }

  .u-bra-1 {
    border-radius: 1px;
  }

  .u-bra-2 {
    border-radius: 2px;
  }

  .u-bra-3 {
    border-radius: 3px;
  }

  .u-bra-4 {
    border-radius: 4px;
  }

  .u-bra-5 {
    border-radius: 5px;
  }

  .u-bg {
    background-color: $background-color;
  }

  @each $name, $color in $palxColors {
    @include palxUtilityColor($name, $color);
  }
}

@charset "UTF-8";

/// Provides a quick method for targeting `margin` on specific sides of a box. Use a `null` value to “skip” a side.
///
/// @param {Arglist} $vals
///   List of arguments
///
/// @example scss - Usage
///   .element {
///     @include margin(null 10px 3em 20vh);
///   }
///
/// @example css - CSS Output
///   .element {
///     margin-bottom: 3em;
///     margin-left: 20vh;
///     margin-right: 10px;
///   }
///
/// @require {mixin} directional-property
///
/// @output `margin`

@mixin margin($vals...) {
  @include directional-property(margin, false, $vals...);
}

@mixin loadingSpinner($color: hsl(0, 0%, 46%), $size: 3rem) {
  width: $size;
  height: $size;
  z-index: $z-over-content + 1;
  animation: fa-spin .6s infinite linear;
  border-radius: 500rem;
  border: .2rem solid;
  border-color: $color rgba(0,0,0,.1) rgba(0,0,0,.1) rgba(0,0,0,.1);
  box-shadow: 0 0 0 1px transparent;
}

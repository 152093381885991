$media-inline-spacing: .4em;

.o-media-list {
  &__item {
    margin-bottom: $spacing-medium;
    padding-bottom: $spacing-medium;
    border-bottom: 1px solid theme-color(gray1);
  }

  &--compact .o-media-list__item {
    margin-bottom: $spacing-small;
    padding-bottom: $spacing-small;
  }
}

.o-media-list .o-media-list {
  padding-left: $spacing-medium;
  border-left: 1px solid theme-color(gray1);

  &__item:first-child {
    margin-top: $spacing-medium;
  }
}

.o-media {
  &__file {
    width: 4.5em;
    max-width: 100px;
    text-align: center;

    .icon {
      font-size: 3.5em;
      margin: 0;
      vertical-align: middle;
      margin: .1em 0 .08em;
    }
  }

  &__author {
    display: inline-block;
    font-weight: bold;
    margin-right: $media-inline-spacing;
  }

  &__metadata {
    display: inline-block;
    margin-right: $media-inline-spacing;
    font-size: $text-font-size-small;
    color: theme-color(gray8);
  }

  &__content {
    overflow-wrap: break-word;
    word-wrap: break-word;
    margin: .25em 0 .5em;

    > p:first-child {
      margin-top: 0;
    }

    > p:last-child {
      margin-bottom: 0;
    }
  }

  &__uploads {
    font-size: .75em;
    margin-left: -1em;

    .o-upload-list__item:last-child {
      border-bottom: 0 none;
    }
  }

  &__actions {
    font-size: $text-font-size-small;

    .o-media__action {
      color: theme-color(gray7);
    }
  }

  &__action {
    display: inline-block;
    margin-right: $media-inline-spacing;
    font-size: $text-font-size-small;
  }
}

@charset "UTF-8";

/// Checks for a valid CSS size.
///
/// @param {String} $value
///
/// @require {function} contains
/// @require {function} is-length

@function is-size($value) {
  @return is-length($value)
          or contains("fill" "fit-content" "min-content" "max-content", $value);
}

.o-modal {
  transform: translate(0, 0);

  &--small {
    width: $modal-width / 2;
  }

  &--medium {
    width: $modal-width * .75;
  }

  &--large {
    width: $modal-width * 1.25;
  }

  // we really need to just override blade properly instead of these hacks
  .c-card {
    background-color: $card-background-color;
    box-shadow: $card-box-shadow;
  }
}

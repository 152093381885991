.o-profile-picture {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
  position: relative;
  border-radius: 50%;
  border: 1px solid theme-color(gray, 2);
  overflow: hidden;
  line-height: 0;

  &--editable {
    cursor: pointer;
  }

  &__label {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: $text-font-size-xsmall * 2.5;
    line-height: $text-font-size-xsmall * 2.2;
    color: white;
    background-color: rgba(black, .6);
    font-size: $text-font-size-xsmall;
    text-align: center;
    cursor: pointer;
  }
}

.c-item {
  &__heading {
    font-size: .96em;
    color: map-get($palxColors, blue5);

    &--basic {
      font-size: 1em;
      color: $color-quiet;
    }
  }

  &__description {
    //
    &--padded {
      padding: .5em 0;
    }
  }
}

.c-item + .c-item {
  margin-top: 1em;
}
